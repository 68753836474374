// Horizonal padding is used for fit layout in a 16:9 aspect
// ratio screen
$padding-h: 2.5rem;
.grid {
    $self: &;
    // flex-wrap: wrap;
    &,
    &-counter {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        &-col {
            max-width: 100%;
        }
    }
    &-counter {
        padding: 0 0.5rem; // distance from header
        flex: 1; // take up available space
        overflow-x: hidden;
        &-col {
            height: calc(100% + #{$padding-h});
            flex: 1 0 100%;
            &.-left {
                // 40%
                max-width: calc(50% + #{$padding-h});
                flex: 1 0 calc(50% + #{$padding-h});
            }
            &.-right {
                // 35%
                max-width: calc(49% + 3*#{$padding-h});
                flex: 1 0 calc(49% + 3*#{$padding-h});
            }
            &.-middle {
                // 25%
                // Currently middle column is substituted by flyout
                display: none;
                max-width: calc(1% + #{$padding-h});
                flex: 1 0 calc(1% + #{$padding-h});
                padding: 0 calc($padding-h / 2);
            }
            @media (min-aspect-ratio: 16/9) {
                &.-left {
                    max-width: calc(44% + #{$padding-h});
                    flex: 1 0 calc(44% + #{$padding-h});
                }
                &.-right {
                    max-width: calc(55% + 3*#{$padding-h});
                    flex: 1 0 calc(55% + 3*#{$padding-h});
                }
            }
            @media (max-width: $screen-small) {
                &.-left {
                    max-width: calc(55% + #{$padding-h});
                    flex: 1 0 calc(55% + #{$padding-h});
                }
                &.-right {
                    max-width: calc(44% + 3*#{$padding-h});
                    flex: 1 0 calc(44% + 3*#{$padding-h});
                }
            }
            @media (max-width: $screen-ipad) {
                &.-left {
                    max-width: calc(60% + #{$padding-h});
                    flex: 1 0 calc(60% + #{$padding-h});
                }
                &.-right {
                    max-width: calc(39% + 3*#{$padding-h});
                    flex: 1 0 calc(39% + 3*#{$padding-h});
                }
            }
        }
    }
    &.-p5 {
        &:not(.-padded-h) {
            margin: 0 -0.3125rem;
        }
    }
    &.-padded-h {
        margin: 0rem -2.5rem;
    }
    &-col {
        flex: 0 0 100%;
        @at-root #{$self}.-padded-h>& {
            padding: 0 $padding-h;
        }
        @at-root #{$self}:not(.-padded-h).-p5>& {
            padding: 0 0.3125rem;
        }
        &.-h-full {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        // Add granularity for common situations
        @each $cols in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12) {
            &.\(1\/#{$cols}\) {
                max-width: calc((1 / $cols) * 100%);
                flex: 0 0 calc((1 / $cols) * 100%);
            }
        }
        // extend as needed
        &.\(3\/4\) {
            max-width: calc((3 / 4) * 100%);
            flex: 0 0 calc((3 / 4) * 100%);
        }
        &.\(2\/5\) {
            max-width: calc((2 / 5) * 100%);
            flex: 0 0 calc((2 / 5) * 100%);
        }
        &.\(2\/3\) {
            max-width: calc((2 / 3) * 100%);
            flex: 0 0 calc((2 / 3) * 100%);
        }
        &.\(3\/5\) {
            max-width: calc((3 / 5) * 100%);
            flex: 0 0 calc((3 / 5) * 100%);
        }
        &.\(2\/12\) {
            max-width: calc((2 / 12 ) * 100%);
            flex: 0 0 calc((2 / 12 ) * 100%);
        }
        &.-force-center {
            &-left {
                transform: translateX(-#{calc($padding-h / 2)});
            }
            &-right {
                // transform: translateX(#{$padding-h / 2});
                transform: translateX(0.5rem);
            }
        }
    }
}