// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$bg-color: #001e3e;
$border-inn-color: #0c3e77;
$red-border: #c20729;
$yellow-border: #e0ad07;
$green-border: #296868;
$blue-color: #2aacd3;
$blue-color-text: #093159;
$second-button: #1de4c9;
$bg-input: #fff;
$team-color-primary: #14bbff;
$team-color-primary-goalkeeper: #00577a;
$team-color-secondary: #2de6cd;
$team-color-secondary-goalkeeper: #0d6d60;
$team-color-tertiary: #ecb22e;
$team-color-tertiary-goalkeeper: #c48a06;
$team-color-quaternary: #e01e5a;
$team-color-quaternary-goalkeeper: #c2003c;
$team-color-quinary: #000;
$team-color-quinary-goalkeeper: #444;

// #0e375f;
$modal-background:#F1F5F8;

$sidebarWidthCollapsed: 86px;
$sidebarWidthOpened: 312px;
$form-control-height: 44px;

// App Counter variables
@import 'color-palette';
@import './spacing.scss';

// $white: get-color((step: 50, color: mono));
$white: #fff;
$red: #e64115;
$yellow: #bea055;
$yellow-button: #e0ad07;
$green: #41946e;
$blue: #1e78ff;
$active-bg: get-color((color: mono, step: 950));

$page-padding: 0;
$page-bg: get-color((step: 50, color: mono));
$page-header-bg: get-color((step: 950)); // defaults primary, default colors, 500
$page-header-padding: 0.5rem;
$page-font: 'Open Sans';

$team-left-color: get-color((color: terniary));
$team-left-color-goalkeeper: get-color((color: terniary, step: 950));
$team-right-color: get-color((color: secondary));
$team-right-color-goalkeeper: get-color((color: secondary, step: 950));

$grid-color: get-color((step: 950));
$screen-smallest: 980px;
$screen-small: 1120px;
$screen-large: 1380px;
$screen-ipad: 1024px;

/** Ionic CSS Variables **/
:root {

  --handball-color-importance: #1de4c9;
  --handball-color-form-validation-error: #fdcbd4;
  /** primary **/
  --ion-color-primary: #36ABE0;
  // #0d385f;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #36ABE0;
  --ion-color-primary-tint: #36ABE0;

  /** alternative **/
  --ion-color-alternative: #CBE6F5;

  // #2aacd3;
  --ion-color-alternative-rgb: 61, 194, 255;
  --ion-color-alternative-contrast: #000;
  --ion-color-alternative-contrast-rgb: 0, 0, 0;
  --ion-color-alternative-shade: #36abe0;
  --ion-color-alternative-tint: #50c8ff;

  /** secondary **/
  --ion-color-secondary: #0D385F;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0d375fc0;
  --ion-color-secondary-tint: #153f67;

  /** tertiary **/
  --ion-color-tertiary: #020f28;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #1de4c9;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #0D385F;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #1de4c9;
  --ion-color-success-tint: #1de4c9;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #E64115;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // HEder-color

  --ion-color-blue-header: #0D385F;


  /** custom variables **/
  --game-scoreboard-header-height: 100px;
  --ion-color-light-green: #0CC778 ;
  --ion-color-yellow: #FFB300;


  --ion-color-background-lignt-gray: #F1F5F8;
  // font

   --ion-font-family: 'Montserrat' !important;
}

.customTimer {
  display: inline;



}

.customTimer div span:nth-of-type(3)::after  {
  content: ":";

}



.customTimer span:nth-child(1),
.customTimer span:nth-child(2),
.customTimer .time-category {
  display: none;
}


// ---------
// DARK MODE
// ---------



// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66, 140, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-toolbar-background: #0d0d0d;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }



