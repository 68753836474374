$header-bg: $page-header-bg;
$header-text-color: $white;
$boxed-border-color: $page-header-bg;
$row-soften-bg: get-color((color: mono, step: 100));

$plain-header-color: $blue;
$plain-body-border-color: $plain-header-color;
$plain-row-soften-bg: rgba($blue, 0.2);

.table {

  &-stats {
    $self: &;
    // Structure
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.6875rem;

    &.-boxed {
      border: 1px solid $boxed-border-color;
    }

    @media (min-height: $screen-smallest), (min-width: $screen-large) {
      font-size: .8125rem;
    }

    &-header,
    &-row {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &-header,
    & {
      &-col {
        flex: 1;
        &.-double {
          flex: 2;
        }
      }
    }
    &-header-col {
      padding: 5px;
    }
    &-col {
      padding: 2.5px 5px;
    }
    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    // Theming Default
    &-header {
      background-color: $header-bg;
      color: $header-text-color;
      @at-root #{$self}.-plain & {
        background-color: transparent;
        color: $page-header-bg;
        border-bottom: 1px solid $plain-header-color;
      }
      &-col {
        @at-root #{$self}.-plain & {
          color: $plain-header-color;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
    &-col {
      font-weight: normal;
      @at-root #{$self}.-plain & {
        display: flex;
        padding: 0.5rem;
        border-left: 1px solid $plain-body-border-color;
        text-transform: uppercase;
        text-align: left;
        font-weight: 600;

        &:first-child {
          border-left-width: 0;
        }

        > span {
          margin-left: auto;
          font-weight: 700;
        }

        &.-dashed {
          border-style: dashed;
        }
      }
    }
    &-row {
      &:nth-child(2n+2) {
        #{$self}-col {
          background-color: $row-soften-bg;
        }
        @at-root #{$self}.-plain & {
          #{$self}-col {
            background-color: $plain-row-soften-bg;
          }
        }
      }
    }
    &-body {
      @at-root #{$self}.-plain & {
        border: 1px solid $plain-body-border-color;
        border-top-width: 0;
      }
    }
  }
}