// Abstract class for items as: timeout & Field
$page-bg: get-color((step: 50, color: mono));
$page-header-bg: get-color((step: 950));
$team-left-color: get-color((color: terniary));
$team-right-color: get-color((color: secondary));
.field {
    $self: &;
    width: 100%;
    display: flex;
    flex-direction: column;
    &-section {
        width: 100%;
        display: flex;
        line-height: 2;
        >* {
            flex: 1 0 auto;
        }
    }
    $distance-between-items: .0625rem; // 1px
    $distance-between-items-timeout: .5rem; // 1px
    &-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -$distance-between-items;
        @at-root #{$self}.-full-space & {
            margin: 0 -0.125rem -0.125rem 0;
        }
        @at-root #{$self}.-timeout & {
            margin: -$distance-between-items-timeout;
        }
    }
    &-player-items {
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
    }
    &-item {
        max-width: calc((100 / 4) * 1%);
        flex-basis: calc((100 / 4) * 1%);
        padding: $distance-between-items;
        line-height: 1;
        overflow: hidden;
        &.-push {
            // margin-left: (100/4)*1%;
            // &.\-2 {
            //     margin-left: 2*(100/4)*1%;
            // }
        }
        &.-chevron {
            padding: 0.25rem;
        }
        @at-root #{$self}.-full-space & {
            &:not(.-chevron) {
                padding: 0 0.125rem 0.125rem 0;
            }
        }
        @at-root #{$self}.-timeout & {
            padding: $distance-between-items-timeout;
            &:nth-child(2),
            &:nth-child(3) {
                margin-left: calc((100 / 6 / 2) *1%);
            }
            &>button {
                &:disabled {
                    &.-can-disable {
                        opacity: 0.1; // needs more opacity as it is close to the active state
                    }
                }
            }
        }
        &-content {
            @include button-reset;
            &.-active {
                background-color: get-color((color: mono, step: 950)) !important;
                border: none !important;
            }
            &.-suspended {
                background-color: $yellow !important;
                &::after {
                    width: 100%;
                    height: 4px;
                    display: block;
                    position: absolute;
                    top: 0;
                }
            }
            &.-suspended-red {
                background-color: $red !important;
                &::after {
                    width: 100%;
                    height: 4px;
                    display: block;
                    position: absolute;
                    top: 0;
                }
            }
            @at-root #{$self}-item.-chevron & {
                background-color: get-color((color: mono, step: 100)) !important;
                #{$self}-item-label {
                    color: get-color((color: mono, step: 950));
                }
                &:active {
                    background-color: get-color((color: mono, step: 50)) !important;
                }
            }
            @at-root #{$self}.primary & {
                background-color: $team-color-primary;
                &.-empty-goal {
                    background-color: transparent;
                }
                &.-goalkeeper {
                    background-color: $team-color-primary-goalkeeper;
                    &.-suspended {
                        position: relative;
                        overflow: hidden;
                        &::after {
                            content: '';
                            background-color: $team-color-primary-goalkeeper;
                        }
                    }
                    &.-suspended-red {
                        position: relative;
                        overflow: hidden;
                        &::after {
                            content: '';
                            background-color: $team-color-primary-goalkeeper;
                        }
                    }
                }
            }
            @at-root #{$self}.secondary & {
                background-color: $team-color-secondary;
                &.-empty-goal {
                    background-color: transparent;
                }
                &.-goalkeeper {
                    background-color: $team-color-secondary-goalkeeper;
                    &.-suspended {
                        position: relative;
                        overflow: hidden;
                        &::after {
                            content: '';
                            background-color: $team-color-secondary-goalkeeper;
                        }
                    }
                }
            }
            @at-root #{$self}.tertiary & {
                background-color: $team-color-tertiary;
                &.-empty-goal {
                    background-color: transparent;
                }
                &.-goalkeeper {
                    background-color: $team-color-tertiary-goalkeeper;
                    &.-suspended {
                        position: relative;
                        overflow: hidden;
                        &::after {
                            content: '';
                            background-color: $team-color-tertiary-goalkeeper;
                        }
                    }
                }
            }
            @at-root #{$self}.quaternary & {
                background-color: $team-color-quaternary;
                &.-empty-goal {
                    background-color: transparent;
                }
                &.-goalkeeper {
                    background-color: $team-color-quaternary-goalkeeper;
                    &.-suspended {
                        position: relative;
                        overflow: hidden;
                        &::after {
                            content: '';
                            background-color: $team-color-quaternary-goalkeeper;
                        }
                    }
                }
            }
            @at-root #{$self}.quinary & {
                background-color: $team-color-quinary;
                &.-empty-goal {
                    background-color: transparent;
                }
                &.-goalkeeper {
                    background-color: $team-color-quinary-goalkeeper;
                    &.-suspended {
                        position: relative;
                        overflow: hidden;
                        &::after {
                            content: '';
                            background-color: $team-color-quinary-goalkeeper;
                        }
                    }
                }
            }
            &.-empty-goal {
                background-color: transparent;
                border: 0.125rem dashed $page-header-bg;
                &.square {
                    // ! box-sizing is content-box. Adjust w,h accordingly
                    width: calc(100% - 2*0.125rem);
                    padding-top: calc(100% - 2*0.125rem);
                }
            }
        }
        &-label {
            display: flex;
            flex-direction: column;
            color: black;
            text-transform: uppercase;
            @media (min-width: $screen-large) {
                font-size: 2.5vw;
                line-height: 2.8vw;
                .text-xs {
                    font-size: 1rem;
                }
            }
        }
        &-timeout-label {
            display: flex;
            flex-direction: column;
            color: white;
            text-transform: uppercase;
            @media (min-width: $screen-large) {
                font-size: 2.0vw;
                line-height: 2.3vw;
                .text-xs {
                    font-size: 1rem;
                }
            }
        }
    }
}

.suspended-player {
    background-color: white !important;
    border-style: dashed;
    border-color: black;
    border-width: 1px;
}

.place-content-end {
    place-content: end;
}