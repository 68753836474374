@import "button.mixins";

$border-width: .0625rem;

.button {
  &-reset {
    @include button-reset;
  }
  &-base {
    @include button-reset;
    &.-inverted {
      background-color: get-color((color: secondary, step: 800));
    }
  }
  &-bottom-line {
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    bottom: -1px;
    background-color: $page-header-bg;
  }
  &-action {
    @include button-reset;
    padding: 12px 30px;
    color: $white;
    background-color: $page-header-bg;
    border-radius: 0.25rem;
    border: 2px solid $page-header-bg;
    //background-color: $page-header-bg;
    font-size: .9375rem;

    &:hover {
      border-color: $page-header-bg !important;
      background-color: transparent;
      color: $page-header-bg;
    }
    &.-cancel {
      border-color: transparent;
      color: $page-header-bg;
      background-color: transparent;
    }
    &.-outline {
      border-color: $page-header-bg !important;
      color: $page-header-bg;
      background-color: transparent;
    }
  }
}


.button-group {
  // Base class for 2x2 group of buttons
  &-2x2 {
    $self: &;
    @include button-group-base-2x2($self, $border-width); //border-width => deprecated
    // at the moment there's only one case of 2x2 -blue.
    &.-blue {
      > button {
        color: $blue;
        &::after {
          background-color: $blue;
        }

        @at-root #{&}.-active {
          background-color: $blue;
          color: $white;
        }
      }
    }
    &.-red {
      > button {
        color: $red;
        &::after {
          background-color: $red;
        }

        @at-root #{&}.-active {
          background-color: $red;
          color: $white;
        }
      }
    }
    &.-green {
      > button {
        color: $green;
        &::after {
          background-color: $green;
        }

        @at-root #{&}.-active {
          background-color: $green;
          color: $white;
        }
      }
    }
    &.-yellow {
      > button {
        color: $yellow;
        &::after {
          background-color: $yellow;
        }

        @at-root #{&}.-active {
          background-color: $yellow;
          color: $white;
        }
      }
    }
  }
  // Button of 2x2 + btn below
  // Outline styling
  // Deprecated
  &-2x2b1 {
    $self: &;
    @include button-group-base-2x2($self, $border-width);
    :disabled {
      span {
        color: $white;
        opacity: 0.4;
      }
    }
    &.-team-A {
      border-color: get-color((step: 900, color: terniary));
      button {
        color: get-color((step: 900, color: terniary));
        border-color: get-color((step: 900, color: terniary));

        &.-active > *,
        &:active > * {
          color: get-color((step: 50, color: mono));
          background-color: get-color((step: 900, color: terniary));
        }
      }
    }

    &.-team-B {
      border-color: get-color((step: 1000, color: secondary));
      button {
        color: get-color((step: 1000, color: secondary));
        border-color: get-color((step: 1000, color: secondary));

        &.-active > *,
        &:active > * {
          color: get-color((step: 50, color: mono));
          background-color: get-color((step: 1000, color: secondary));
        }
      }
    }

    &-top {
      &-left {
        border-width: 0;
        border-bottom-style: dashed;
        border-right-style: dashed;
        border-right-width: $border-width;
        border-top-left-radius: .25rem;
      }
      &-right {
        border-width: 0;
        border-top-right-radius: 0.25rem;
      }
    }
    &-bottom {
      &-left {
        border-width: 0;
        border-right-style: dashed;
        border-right-width: $border-width;
        border-top-width: $border-width;
        border-top-style: dashed;
        border-top-left-radius: 0;
      }
      &-right {
        border-width: 0;
        border-top-right-radius: 0;
        border-top-width: $border-width;
        border-top-style: dashed;
      }
    }
    &-bottom {
      border: 0 solid;
      border-top-width: $border-width;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      &:disabled {
        span {
          color: $white;
          opacity: 0.3;
        }
      }
    }
  }
  // Button of 2x2 + btn next to the right
  // Deprecated
  &-2x2r1 {
    $self: &;
    display: flex;
    position: relative;
    align-items: flex-start;
    color: get-color((step: 50, color: mono));
    background-color: get-color((step: 950));


    > * {
      max-width: 50%;
      flex: 0 0 50%;
      @include button-reset;
      overflow: hidden;
    }

    span > span {
      width: 100%;
      line-height: 1.1;
      text-transform: uppercase;
      font-weight: 600;
    }

    #{$self}-right {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      border-left: $border-width solid $white;

      &:disabled {
        span {
          opacity: 0.4;
        }
      }

      &.-active {
        background-color: $active-bg;
        .rectangle,
        .rectangle > span {
          background-color: $active-bg;
        }
      }
    }
  }
  &-stacked {
    $self: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 0.25rem;
    @include button-group-stacked($self);
    // TODO Repetitive pattern. It Can be refacted.
    &.-red {
      > button {
        color: $red;
        &::after {
          background-color: $red;
        }
        @at-root #{&}.-active {
          color: $white;
          background-color: $red;
        }
      }
    }
    // Blue for this case isn't used at the moment!
    &.-blue {
      > button {
        color: $blue;
        &::after {
          background-color: $blue;
        }
        @at-root #{&}.-active {
          color: $white;
          background-color: $blue;
        }
      }
    }
    &.-yellow {
      > button {
        color: $yellow;
        &::after {
          background-color: $yellow;
        }
        @at-root #{&}.-active {
          color: $white;
          background-color: $yellow;
        }
      }
    }
    &.-green {
      > button {
        color: $green;
        &::after {
          background-color: $green;
        }
        @at-root #{&}.-active {
          color: $white;
          background-color: $green;
        }
      }
    }
  }
}

.button-result {
  width: 100%;
  position: relative;
  @include button-box-shadow;

  &:active {
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
    opacity: 0.4;
    //&::before {
    //  content: '!';
    //  width: 1.5rem;
    //  height: 1.5rem;
    //  position: absolute;
    //  left: 50%;
    //  top: -(1.5rem/2);
    //  transform: translateX(-50%);
    //  color: $white;
    //  background-color: $red;
    //  border-radius: 50%;
    //  line-height: 1.5rem;
    //  z-index: 99;
    //}
    .square {
      background-color: get-color((step: 950, color: mono)) !important;
    }
  }
  .square {
    color: $white;
    span {
      font-weight: bolder;
      text-transform: uppercase;
    }
  }
  &.-red {
    .square {
      background-color: $red;
    }
  }
  &.-blue {
    .square {
      background-color: $blue;
    }
  }
  &.-yellow {
    .square {
      background-color: $yellow;
    }
  }
  &.-yellow-btn {
    .square {
      background-color: $yellow-button;
    }
  }
  &.-green {
    .square {
      background-color: $green;
    }
  }
}
