@mixin button-box-shadow($color: #000, $alpha1: 0.8, $alpha2: 0.1) {
  box-shadow: 0px 0px 2px -1px rgba($color,$alpha1), 0px 0px 4px 0px rgba($color,$alpha2);
}

@mixin can-disable {
  &:disabled {
    opacity: 0.3;
  }
}
@mixin button-reset($with-can-disable: true) {
  margin: 0;
  appearance: none;
  box-shadow: none;

  &:focus,
  &:active {
    outline: none !important; // avoid buggy outlines no matter what
  }
  @if $with-can-disable == true {
    &.-can-disable {
      @include can-disable;
    }
  }
}

@mixin button-group-base-2x2($self, $border-width) {
  $separation: 0.25rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: .25rem;

  & + #{$self} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  > button {
    position: relative;
    max-width: calc(50% - #{calc($separation / 2)});
    flex: 0 0 calc(50% - #{calc($separation / 2)});
    @include button-reset(false);
    overflow: hidden;
    @include button-box-shadow;
    @include can-disable;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: get-color((step: 950));
    }

    &:active:not(:disabled) {
      box-shadow: none;
    }

    &:nth-child(2n+1) {
      margin-right: calc($separation / 2);
    }
    &:nth-child(2n) {
      margin-left: calc($separation / 2);
    }

    &:nth-child(3),
    &:nth-child(4) {
      margin-top: $separation;
    }
  }

  span > span {
    width: 100%;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .875rem;

    @media (max-width: $screen-smallest) {
      font-size: .75rem;
    }
  }

  // Deprecated
  // Only used in 2x2b1
  > #{$self}-bottom {
    max-width: 100%;
    flex: 0 0 100%;
  }

  &.-inverse {
    color: get-color((step: 50, color: mono));
    background-color: get-color((step: 950));
  }
}

@mixin button-group-stacked($self) {

  > button {
    max-width: 100%;
    flex: 0 0 100%;
    position: relative;
    @include button-reset;
    margin-bottom: 0.25rem;
    overflow: hidden;
    @include button-box-shadow;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: get-color((step: 950));
    }

    &:active:not(:disabled) {
      box-shadow: none;
    }

    @include can-disable;

    > .rectangle,
    > .square {
      & > span {
        width: 100%;
        text-transform: uppercase;
        font-size: .875rem;
        font-weight: 600;

        @media (max-width: $screen-smallest) {
          font-size: .75rem;
        }
      }
    }
  }
}