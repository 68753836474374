//
// Common utility class for generating squared/circled content
//

.square,
.circle,
.rectangle {
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  padding-top: 100%;
  box-sizing: content-box;
  > * {
    // w & h auto to allow center alignment
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.circle {
  border-radius: 50%;
}

.rectangle {
  padding-top: 50%;
  &.-\(2\/3\) {
    padding-top: calc((100 / 3) * 1%);
  }
  &.-\(1\/2\) {
    padding-top: 23%;
  }
  &.-\(1\/4\) {
    padding-top: calc((50 / 4) * 1%);
  }
  &.-\(1\/5\) {
    padding-top: calc((1 / 5) * 100%);
  }
  &.-\(45\) {
    padding-top: 42%;
  }
}

.square {
  &.-\(1\/2\) {
    padding-top: 50%;
  }
  &.-\(1\/4\) {
    padding-top: 25%;
  }
}
.square-popup {
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  padding-top: 100%;
  box-sizing: border-box;
  > * {
    // w & h auto to allow center alignment
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
