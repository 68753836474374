/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import './theme/variables.scss';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'assets/sass/components/grid';
@import 'assets/sass/components/button';
@import 'assets/sass/components/field';
@import 'assets/sass/components/shapes';
@import 'assets/sass/components/table';
@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import 'assets/fonts/fonts.css';
@font-face {
  font-family: 'Kameron';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Kameron-Bold.ttf');
}

@font-face {
  font-family: 'Kameron-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Kameron-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/OpenSans-SemiBold.ttf');
}

body {
  font-size: 1em;
}

@media only screen and (max-width: 1200px) {
  body {
    font-size: 0.6em;
  }
  .text-xxs {
    font-size: 0.4rem;
  }
}

@media only screen and (min-width: 1201px) {
  body {
    font-size: 1em;
  }
  .w-8 {
    width: 4rem !important;
  }
  .h-8 {
    height: 4rem !important;
  }
  .text-sm {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  .text-xxs {
    font-size: 0.8rem;
  }
}

.card-container {
  display: flex;
  width: 100%;
  background-color: var(--ion-color-background-lignt-gray);
  // box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}

.card-container-white {
  display: flex;
  width: 100%;
  background-color: #fff;
}

.card-container-transparent {
  display: flex;
  width: 100%;
  background-color: transparent;
  // box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}

.card-margins {
  margin-top: 30px;
  margin-bottom: 20px;
}

.flex-vertical-align {
  align-items: center;
  justify-content: center;
}


ion-modal#modal-full-width {
  --height: fit-content;
  --width: 98vw;
}

.modal-edit-event {
  &>.ion-page {
    padding: 0;
  }
  &::part(content) {
      border: 2px solid lightgrey;
  }
}


ion-modal::part(content) {
  background: transparent;
}

.center-pl {
  justify-content: center;
}

.full-border {
  border-width: 1px;
  border-style: solid;
  border-color: $border-inn-color;
}

.left-border {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: $border-inn-color;
}

.right-border {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $border-inn-color;
}

.bottom-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $border-inn-color;
}

.iconplay {
  background-color: #fff;
  border-radius: 55px;
  padding: 5px;
}

.full-border-red {
  border-width: 1px;
  border-style: solid;
  border-color: $red-border;
}

.full-border-yellow {
  border-width: 1px;
  border-style: solid;
  border-color: $yellow-border;
}

.full-border-green {
  border-width: 1px;
  border-style: solid;
  border-color: $green-border;
}

.full-border-white {
  border-width: 1px;
  border-style: solid;
  border-color: white;
}

.color-red {
  color: $red-border;
}

.color-yellow {
  color: $yellow-border;
}

.color-green {
  color: $green-border;
}

.color-blue {
  color: $border-inn-color;
}

.yellow-background {
  --background: var(--ion-color-yellow);
  background-color: var(--ion-color-yellow);
}

.my-custom-modal-css .modal-wrapper {
  height: 300px;
  position: absolute;
  display: block;
}

.popover-wrapper, .popover-content, .modal-wrapper,
ion-popover::part(content),
ion-modal::part(content) {
  // Fix for screen recorders (allow to record the screen with popups)
  opacity: 1 !important;
}

ion-modal > .ion-page {
//  padding: 20px;
  padding-top: 0;
}

ion-modal.create-player-modal.wide {
  &::part(content) {
    background-color: $modal-background !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90% !important;
    max-width: 90% !important;
    height: 90%;
  }
  &> .ion-page {
    width: 100%;
    border-radius: 30px;
  }
}

ion-popover.mySelect::part(content) {
  top: 17%;
  left: 25%;
}

ion-popover.mySelectFolder::part(content) {
 // top: 40%;
 // left: 26%;
}

ion-modal#create-player-modal {
  --height: fit-content;
  --width: fit-content;
  --max-width: 972px;
  --border-radius: 30px;
  &> .ion-page {
    background-color: $modal-background !important;
  }
}

ion-popover::part(content) {
  border-radius: 30px !important;
  padding: 10px !important;
}

.new_button_success {
  background-color: $blue-color;
  color: $blue-color-text;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1.5rem;
}

.new_button_second {
  background-color: $second-button;
  color: $blue-color-text;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1.5rem;
}

.new_input_style {
  background-color: $bg-input;
  --background: #0e375f;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1.5rem;
  color: white;
}

.optimize-contrast {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
}

.global {
  &__form-elements--validation-error {
    background-color: var(--handball-color-form-validation-error);
  }
  &__form-elements-validator-msg {
    color: var(--ion-color-danger);
    font-size: 0.6rem;
    margin-left: 1rem;
    margin-top: 0.25rem;
  }
  &__icon-text {
    display: inline-block;
    vertical-align: middle;
  }
  &__main-page {
    background: var(--ion-color-background-lignt-gray);
    color: var(--ion-color-primary-contrast);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  &__game-page {
    background: var(--ion-color-background-lignt-gray);
    color: var(--ion-color-primary-contrast);
    width: 100%;
    height: 100%;
  }
  &__game-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__game-page-text--blue {
    background-color: $bg-input;
    color: $blue-color;
    font-family: 'Kameron-Regular';
    font-size: 1.5rem;
  }
  &__game-page-text--green {
    background-color: $bg-input;
    color: $second-button;
    font-family: 'Kameron-Regular';
    font-size: 1.5rem;
  }
  &__game-page-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__brand {
    font-family: 'Kameron';
    font-size: 2.6rem;
  }
  &__brand-small {
    font-family: 'Kameron';
    font-size: 1.5rem;
  }
  &__dateTime-popover {
    &::part(content) {
      --width: auto;
    }
  }
  &__team-verification-popover {
    &::part(content) {
      --width: 80%;
      background: var(--ion-color-background-lignt-gray) !important;
    }
    &__playeredit {
      &::part(content) {
        --width: 90%;
        background: var(--ion-color-background-lignt-gray) !important;
        height: 100%;
      }
        app-start-game-edit-team-popover {
          height: 100%;
          background: var(--ion-color-background-lignt-gray);
          ion-card {
            box-shadow: none;
          }
        }
    }
  }
  // This one is a very simple solution to
  // work around the situation that everything has been
  // been supposed to work for mobile devices only
  &__content-container {
    width: 75%;
    @media screen and (min-width: 1280px) {
      width: 50%;
    }
  }
  &__timer-popover {
    &::part(content) {
      --width: 48%;
    }
    span:not(.share_link) {
      font-weight: 800;
      color: var(--ion-color-secondary);
    }
  }
  &__scouting-popover {
    --box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    --width: 50%;
    @media screen and (min-width: 950px) {
      --width: 25%;
    }
  }
  &__popover {
    --width: auto;
    --backdrop-opacity: 0.45;
    &::part(content) {
      --background: #{$white};
      color: $page-header-bg;
      border-radius: 0;
    }
    //Check con la versión antigua
    &--goal {
      &::part(content) {
        --max-height: 97%;
        z-index: 100000 !important;
        // left: calc(100vw / 1.8) !important;
        width: 45%;
        background-color: #fff;
      }
      app-goal-popover-container,
      app-lite-double-event-popover-container,
      app-double-event-popover-container {
        overflow: unset !important;
        height: 100%;
        app-goal-popover,
        app-lite-double-event-popover,
        app-double-event-popover {
          height: 100%;
          background: #fff;

          .lite-double-event-container {
            min-width: unset;
          }
        }
      }
      &.game-system {
        &::part(content) {
          // top: 10px !important;
          right: unset;
          left: 10px !important;
          width: calc(100% - 40px);
          // height: calc(100% - 20px);
          //.card-container-goal {
          //  width: 45%;
          //}
        }
      }
    }
    &--game-system {
      &::part(content) {
        width: 60%;
      }
    }
  }
}

.full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

ion-content.background {
  --background: #f5f5f5;
}

ion-content.transparent {
  --background: transparent;
}

.global__game-page-header div {
  color: #f1f3f4;
}

.card-container.content-bg {
  width: auto;
  // margin: 0 $space-l;
  margin-top: 10px;
  margin-bottom: 40px;
  padding-bottom: 30px;
}

.content-section {
  padding: 0 $space-l;
}

.card-container.auth-page {
  margin-top: 30px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  .card-header {
    background: #0e375f;
    display: flex;
    padding: 0 35px;
    width: 100%;
    justify-content: center;
  }
  .card-content {
    width: 50%;
    margin-bottom: 30px;
  }
}

// .ngx-datatable .datatable-body .datatable-row-wrapper:nth-child(even) .datatable-body-row {
//     background-color: #f0f0f0;
//     .datatable-row-left {
//         background-color: #f0f0f0;
//     }
// }

// No hover effect on tables

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: white;
}

// .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover {
//     background-color: unset !important;
// }
// .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover {
//     background-color: orange !important;
// }

// .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
//     background-color: yellow !important;
// }
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover {
  background-color: unset !important;
}

.datatable-row-center .datatable-row-group:hover {
  background-color: #fff !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  transition: none !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  color: #0e375f;
}

.ngx-datatable,
.card-container {
  // box-shadow: 0 5px 5px -3px rgb(0 0 0 / 0%), 0 8px 10px 1px rgb(0 0 0 / 0%), 0 3px 14px 2px rgb(0 0 0 / 11%) !important;
}

.ngx-datatable.material .datatable-footer {
  overflow: hidden;
}

.modal-invisible {
  height: 0;
  width: 0;
}

ion-modal.modal-pagescreen {
  &::part(content) {
    width: 100%;
    height: 100%;
  }
  > .ion-page {
    padding: 0;
  }
}

ion-modal.no-padding-modal {
  > .ion-page {
    padding: 0;
  }
}

ionic-selectable-modal {
  padding: 0 !important;
}

ion-searchbar {
  margin-top: 10px;
}

.doughnut-outer-wrapper {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;
  .doughnut-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    .doughnut-legend {
      margin-top: 20px;
      margin-bottom: 20px;
      color: var(--ion-color-secondary);
      font-size: 14px;
      display: flex;
      justify-content: center;
    }
  }
}

.bar-outer-wrapper {
  display: block;
  margin-top: 50px;
  margin-bottom: 30px;
  .bar-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.meter {
  box-sizing: content-box;
  height: 8px;
  border-radius: 5px;
  position: relative;
  background: rgba(13, 56, 95, 0.2);
  overflow: hidden;
  > span {
    display: block;
    height: 100%;
    border-radius: 5px;
    background-color: rgb(43, 194, 83);
    position: relative;
    overflow: hidden;
  }
}

// BUTTONS GLOBAL CSS

ion-button {
  background-color: unset !important;
  // width: unset !important;
  height: $form-control-height !important;
  min-width: $form-control-height !important;
}
ion-button::part(native) {
  border-radius: 30px;
  // background-color: #36ABE0 ;
  padding: 5px 10px;
}

// .ion-color-primary {
//     --ion-color-base: #36ABE0 !important
// }

ion-ripple-effect {
  border-radius: 30px !important;
}

.button-padding-small {
  padding: 5px 10px;
}
.button-padding-large {
  padding: 15px 35px;
}

// SELECT GLOBAL

ng-select {
  height: $form-control-height !important;
}

.ng-select-container {
  padding: 20px 10px;
  border-radius: 30px !important;
  min-height: $form-control-height !important;
}
.ng-dropdown-panel,
ng-dropdown-panel {
  border-radius: 30px !important;
  padding: 10px;
  margin-top: 5px !important;
}
.ng-dropdown-panel.team-color {
  // min-width: 150px;
  .ng-option-container {
    display: flex;
    align-items: center;
    span:first-child {
      width: 15px;
      height: 15px;
      border: 1px solid lightgrey;
    }
    .primary {
      background-color: $team-color-primary;
    }
    .secondary {
      background-color: $team-color-secondary;
    }
    .tertiary {
      background-color: $team-color-tertiary;
    }
    .quaternary {
      background-color: $team-color-quaternary;
    }
    .quinary {
      background-color: $team-color-quinary;
    }
  }
  .ng-option.ng-option-selected.ng-option-marked, &.ng-option-marked {
    opacity: 0.7;
  }
}

.icon-wrapper {
  padding: 0 22px;
}

ion-header {
  background-color: var(--ion-color-background-lignt-gray);
  .select-wrapper {
    width: 20%;
    margin-left: auto;
    margin-right: 10px;
  }
}

ion-content::part(background) {
  background-color: var(--ion-color-background-lignt-gray);
}

.global__popover--doubleEvent {
  // --width: var(--hai-gamesystem-enabled-popwidth, 80%);
}

.global__popover--doubleEvent--actions {
  --width: auto;
}

.actions-cell {
  display: flex;
  place-content: flex-end;
}


.confirm-popover {
  &::part(content) {
    width: 100% !important;
    max-width: 657px !important;
    text-align: center;
  }
  span {
    font-weight: 800;
    color: var(--ion-color-secondary);
  }
}


.create-account-popover {
  &::part(content) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

ion-popover.confirm-popover.wide {
  &::part(content) {
    max-width: 85% !important;
    width: 100% !important;
    height: 85% !important;
  }
}

ion-popover.game-options-popover {
  &::part(content) {
    width: 400px!important;
    top: 22% !important
  }
}

ion-popover.game-options-popover.popover-content {
  top: 1% !important
}

.popover-height-reference {
  &::part(content) {
    height: 100%;
  }
  .popover-viewport {
    height: 100%;
  }
}

.scouting-video-popover {
  &::part(content) {
    max-width: 96% !important;
    width: 96% !important;
    text-align: center;
  }
  span {
    font-weight: 800;
    color: var(--ion-color-secondary);
  }
  app-video-modal {
    min-height: 100%;
  }
}

.scouting-video-popover.wide {
  &::part(content) {
    max-width: 96% !important;
    width: 96% !important;
    height: 99% !important;
  }
}

app-account-equipo-edit-popup {
  height: 100%;
}

//datatable global style

::ng-deep {
  .datatable-row-group {
    background-color: white;
    border-radius: 30px;
    margin: 10px 0;

    height: 84px;
    .test {
      width: 300px !important;
    }
    &:last-child {
      width: 300px !important;
    }

    .database-row-group {
      width: 98% !important;
      margin-left: auto;
    }
  }

  .ngx-datatable.material {
    background-color: transparent;
    box-shadow: none !important;
  }

  .datatable-header-cell {
    background-color: transparent !important;
  }

  .datatable-body-cell-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--ion-color-secondary);
    width: 100%;
  }

  .actions-cells-container {
    justify-content: flex-end !important;

    .transfer-player-btn {
      margin: 0 $space-s;
    }
  }

  .datatable-header {
    visibility: hidden;
    height: 0px;
  }
  .datatable-footer {
    visibility: hidden;
  }
}

// INPUT
input {
  background: $bg-input;
  box-shadow: 0px 1px 20px rgb(96 108 128 / 8%);
  border-radius: 30px;
  height: $form-control-height;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #0003;
  &:-internal-autofill-selected {
    background-color: $bg-input !important;
  }
}

.ng-select input {
  height: 36px;
}

ion-input {
  --background: #{$white};
}

.text-light {
  color: #a0abb8;
}

.text-sm {
  font-size: 12px;
}

.register-content,
.login-content {
  display: flex;
  height: 100%;
  position: relative;

  .register-logo-img,
  .login-logo-img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.side-logo-content {
  width: 45%;
  background: var(--ion-color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

@media only screen and (max-width: 900px) {
  .side-logo-content {
    //display: none;
  }
  .card-container.auth-page {
    width: 100%;
  }
}

.register-title,
.login-title {
  color: #fff;
  font-weight: 800;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

// // TOGGLE
// ion-toggle::part(track),
// ion-toggle.toggle-checked::part(track) {
//     background: rgba(14, 55, 95, 0.28);
// }

.toggle-wrapper {
  display: flex;
  align-items: center;
}

// CARD
.handball-card {
  position: relative;
  background: $bg-input;
  box-shadow: 0px 1px 20px rgba(96, 108, 128, 0.08);
  border-radius: $space-l;
  padding: $space-m $space-l;
}

.text-align-center {
  text-align: center;
}

.ion-color-alternative {
  --ion-color-base: var(--ion-color-alternative, #3880ff) !important;
  --ion-color-base-rgb: var(--ion-color-alternative-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-alternative-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-alternative-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-alternative-shade, #3171e0) !important;
  --ion-color-tint: var(--ion-color-alternative-tint, #4c8dff) !important;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  ion-icon {
    font-size: 24px;
  }
}

ionic-selectable ::ng-deep {
  ion-button.button button.button-native {
    background-color: var(--ion-color-secondary) !important;
  }
}

app-stacked-navigation.two-pages-min-width {
  width: 100%;
  div.nav-container-scroller div.nav-container {
    width: fit-content;
    app-stacked-page {
      &:nth-child(1) {
        min-width: calc((100vw - 86px) * 0.4);
        max-width: calc((100vw - 86px) * 0.4);
      }
      &:nth-child(2) {
        min-width: calc((100vw - 86px) * 0.6);
      }
    }
  }
}

app-stacked-navigation div.content-container ion-item:hover {
  --background: rgb(227, 227, 227);
}

.header-title-text {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: var(--ion-color-blue-header);
  padding: 0;
  .title-container {
    display: flex;
    align-items: center;

    button {
      margin: 0 !important;
      padding-left: 5px;
      ion-icon {
        padding-top: 10px;
      }
    }
  }
}

.scoutingPopOverr {
  width: 5000px !important;
  border: 1px solid red;
}

.lightBtn {
  font-size: 0.85rem;
  font-weight: 600;
  background-color: white;
  color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-secondary);
  &:hover {
    background-color: var(--ion-color-light-tint);
  }
}

.greySmallBtn {
  font-size: 0.75rem;
  font-weight: 600;
  background-color: var(--ion-color-light-tint);
  color: var(--ion-color-secondary);
  &:hover {
    background-color: var(--ion-color-secondary);
    color: white;
  }
}

.darkBtn {
  font-size: 0.85rem;
  font-weight: 600;
  background-color: var(--ion-color-secondary);
  color: white;
}

tooltip.keybindingHelpTooltip {
  min-width: 330px;
  ul {
    text-align: left;
    padding-left: 10px;
    margin-bottom: 10px;
  }
}

ion-popover.scouting-videomodal-extrafilters::part(content) {
  width: 600px;
}

ion-popover.scouting-videomodal-extrafilters-with-events::part(content) {
  top: calc(10% + var(--offset-y, 0px)) !important;
  left: calc(23% + var(--offset-x, 0px)) !important;
  bottom: auto;
  width: 1100px;
}


@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

.loading-card {
  z-index: 0;
  position: relative;
}

.loading-card::before {
  content: "";
  width: 102%;
  height: 102%;
  top: -1%;
  left: -1%;
  border-radius: var(--border-radius, 20px);
  background: linear-gradient(var(--rotate), var(--ion-color-primary) 0%, var(--ion-color-primary) 20%, var(--ion-color-secondary) 20%, var(--ion-color-secondary) 50%);
  position: absolute;
  z-index: -1;
  animation: spin 1s linear infinite;
}

.loading-card::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius, 20px);
  background-color: var(--background-color, white);
  margin: 0 auto;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}


.header-md {
  box-shadow: unset !important;
}


ion-toast {
  --width: fit-content;
}

ion-toast::part(icon) {
  color: var(--ion-color-base);
}

ion-toast::part(message) {
  color: black;
}

ion-toast::part(container) {
  background: white !important;
  border-radius: var(--border-radius);
}

.expand-button {
  background: var(--ion-color-secondary);
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 12px;
}

.header-full-pagescreen {
  &::part(content) {
    border-radius: 0 !important;
    left: calc(var(--offset-x, 0))!important;
    width: 35%;
  }
}


.add-player-popover {
  &::part(content) {
    width: 100% !important;
    max-width: 657px !important;
    text-align: center;
  }
  span {
    color: var(--ion-color-secondary);
  }

  .team-title {
    font-weight: 800;
  }
}

ion-popover.add-player-popover.wide {
  &::part(content) {
    max-width: 85% !important;
    width: 100% !important;
    height: 85% !important;
  }
}

.game-system-filter-popover {
  &::part(content) {
    width: 100% !important;
    max-width: 700px !important;
    text-align: center;
    top: calc(50px + var(--offset-y, 0))!important;
    max-height: 90%;
  }
  span {
    font-weight: 800;
    color: var(--ion-color-secondary);
  }
}

.select-folder-popover {
  &::part(content) {
    width: 100% !important;
    max-width: 800px !important;
    text-align: center;
    top: calc(120px + var(--offset-y, 0))!important;
    max-height: 75%;
  }
  span {
    font-weight: 800;
    color: var(--ion-color-secondary);
  }
}

ion-popover.select-folder-popover.wide {
  &::part(content) {
    max-width: 85% !important;
    width: 100% !important;
    height: 85% !important;
  }
}

ion-popover.game-system-filter-popover.wide {
  &::part(content) {
    max-width: 85% !important;
    width: 100% !important;
    height: 85% !important;
  }
}


ion-modal.folder-access-modal.wide {
  &::part(content) {
    background-color: $white !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90% !important;
    max-width: 90% !important;
    height: 90%;
  }
  &> .ion-page {
    width: 100%;
    border-radius: 30px;
  }
}


ion-modal#folder-access-modal {
  --height: fit-content;
  --width: 50%;
  //--max-width: 972px;
  --border-radius: 30px;
  &> .ion-page {
    background-color: $white !important;
  }
}

.display-flex-space-between {
  display: flex;
  justify-content: space-between;
}

app-stacked-navigation.two-pages-min-width.folder {
  div.nav-container-scroller div.nav-container {
    app-stacked-page {
      &:nth-child(2) {
        min-width: calc((100vw - 170px) * 0.6);
      }
    }
  }
}

.scroll-to-top-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}

@media screen and (max-width: 1550px) {
  ion-popover.scouting-videomodal-extrafilters-with-events::part(content) {
    top: calc(5% + var(--offset-y, 0px)) !important;
    left: calc(20% + var(--offset-x, 0px)) !important;
    width: 1000px;
  }
}

@media screen and (max-width: 1300px) {
  ion-popover.scouting-videomodal-extrafilters-with-events::part(content) {
    left: calc(10% + var(--offset-x, 0px)) !important;
    width: 1000px;
  }
}

@media screen and (max-width: 1100px) {
  ion-popover.scouting-videomodal-extrafilters-with-events::part(content) {
    left: calc(var(--offset-x, 0px)) !important;
    width: 1000px;
  }
}

@media screen and (max-width: 768px) {
  .header-title-text {
    font-size: 18px;
  }
  app-stacked-navigation.two-pages-min-width {
    div.nav-container-scroller div.nav-container {
      width: 100%;
    }
  }

  .confirm-popover {
    &::part(content) {
      max-width: 89% !important;
      left: calc(var(--ion-safe-area-left, 0) + var(--offset-x, 0))!important;
      margin-left: 3%;
    }
  }
  .scouting-video-popover {
    &::part(content) {
      max-width: 93% !important;
      top: calc(10px + var(--offset-y, 0)) !important;
    }

    top: calc(var(--offset-y, 0)) !important;
  }
  .scouting-video-popover.wide {
    &::part(content) {
      max-width: 93% !important;
      height: auto!important;
    }
  }

  .select-folder-popover {
    &::part(content) {
      width: 93% !important;
    }
  }

  .global__dateTime-popover::part(content) {
    left: calc(var(--offset-x, 0px)) !important;
  }
  .header-title-text {
    .title-container {
      display: block;
    }
  }

  ng-select ::ng-deep {
    font-size: 15px !important;
  }

  ng-select {
    font-size: 15px !important;
  }

  .side-logo-content {
    width: 100%;
  }

  .global {
    &__timer-popover {
      &::part(content) {
        --width: 95%;
        left: calc(var(--offset-x, 0))!important;
      }
    }
  }

  ion-popover.game-options-popover {
    &::part(content) {
      width: 360px!important;
    }

    left: calc(var(--offset-x, 0px));
  }

  app-stacked-navigation.two-pages-min-width.search-game {
    div.nav-container-scroller div.nav-container {
      app-stacked-page {
        &:nth-child(1) {
          min-width: 100%;
        }
      }
    }
  }


  app-stacked-navigation.two-pages-min-width.folder {
    div.nav-container-scroller div.nav-container {
      app-stacked-page {
        &:nth-child(1) {
          min-width: 90%;
        }
      }
    }
  }

  .expand-toggle {
    display: flex;
    justify-content: end;
    padding: 10px;
  }

  .header-title-text {
    .title-container {
      button {
        margin-bottom: 10px!important;
      }
    }
  }

  .global {
    &__popover {
      &--game-system {
        &::part(content) {
          width: 90%;
          left: calc(var(--offset-x, 0px))!important;
          margin-left: 3%;
        }
      }
    }
  }

  #searcherContainer ng-select {
    height: 35px !important;
  }

  #searcherContainer .ng-select-container {
    min-height: 35px !important;
  }

  .doughnut-outer-wrapper {
    display: block;
    .doughnut-wrapper {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .header-full-pagescreen {
    &::part(content) {
      border-radius: 0 !important;
      left: calc(var(--offset-x, 0))!important;
      height: 100% !important;
      top: calc(var(--offset-y, 0))!important;
      width: 75%;
      padding-bottom: 70px!important;
    }
  }

  .global__scouting-popover {
    &::part(content) {
      left: calc(var(--offset-x, 0))!important;
      width: 89%;
      margin-left: 3%;
    }
  }



  ion-modal#folder-access-modal {
    --width: 90%;
  }

  ion-popover.scouting-videomodal-extrafilters-with-events::part(content) {
    top: calc(87.066685px + var(--offset-y, 0px))!important;
    left: calc(15px + var(--offset-x, 0px))!important;
    width: 93%!important;
  }
}

@media screen and (min-width: 850px) {
  .label-mask::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 42%;
    width: 135%;
    height: 9px;
    background: white;
    z-index: -1;
    border-top-left-radius: 10px;
  }
}
